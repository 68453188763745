!(function ($) {
  _p.each(['get', 'post', 'put', 'del', 'patch', 'delete'], function (method) {
    var func = $[method];
    $[method] = function (url, data, opt) {
      url = url.match(/^\/@api/) ? '/' + T.lang + url : url;
      return func(url, data, opt);
    };
  });
  $.delete = $.del;
  var upload = $.upload;
  $.upload = function (input_or_form, opt) {
    opt = opt || {};
    opt.url = opt.url || $.UPLOAD_URL || '/@fileUpload/file';
    opt.url = opt.url.match(/^\/@api/) ? '/' + T.lang + opt.url : opt.url;
    return upload(input_or_form, opt);
  };
})($);
