/**
 * Created by piljung on 2017. 3. 21..
 */
// partial x donjs
!(function (_, $) {
  var is_image_orientation = getComputedStyle(document.body).imageOrientation == 'from-image';
  $.mobile_check = function () {
    return (function (a) {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      );
    })(navigator.userAgent || navigator.vendor || window.opera);
  };
  $.mobile_tablet_check = function () {
    return (function (a) {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
          a.substr(0, 4)
        )
      );
    })(navigator.userAgent || navigator.vendor || window.opera);
  };

  $.isolatedScroll = function (el, infi) {
    function eventCb(e) {
      var delta = (e.originalEvent && e.originalEvent.wheelDelta) || e.deltaY || -e.detail || e.wheelDelta;
      var bottomOverflow = this.scrollTop + $.outerHeight(this) - this.scrollHeight >= 0;
      var topOverflow = this.scrollTop <= 0;

      if ((delta < 0 && bottomOverflow) || (delta > 0 && topOverflow)) e.preventDefault();
    }
    $.on(el, 'DOMMouseScroll' + (infi ? '.' + infi : ''), eventCb);
    $.on(el, 'mousewheel' + (infi ? '.' + infi : ''), eventCb);

    return el;
  };
  $.isolatedScroll2 = function (el) {
    if (!el) return;
    var outer_height = $.outerHeight(el);
    function eventCb(e) {
      if (outer_height - el.scrollHeight == 0) return;
      var delta = (e.originalEvent && e.originalEvent.wheelDelta) || e.deltaY || -e.detail || e.wheelDelta;
      var bottomOverflow = this.scrollTop + $.outerHeight(this) + 28 - this.scrollHeight >= 0;
      var topOverflow = this.scrollTop <= 0;
      e.stopPropagation();
      if ((delta < 0 && bottomOverflow) || (delta > 0 && topOverflow)) e.preventDefault();
    }

    $.on(el, 'DOMMouseScroll.isolated_scroll2', eventCb);
    $.on(el, 'mousewheel.isolated_scroll2', eventCb);

    return el;
  };
  $.isolatedScroll2_off = function (el) {
    $.off(el, 'DOMMouseScroll.isolated_scroll2');
    $.off(el, 'mousewheel.isolated_scroll2');
    return el;
  };

  $.don_loader_start = function (parent, opt, timeout) {
    return _.go(
      $('.don_loader_wrap'),
      $.remove,
      _.noop,
      _.t$('\
      .don_loader_wrap\
        .don_loader_img'),
      $.el,
      opt ? $.css(opt) : _.idtt,
      $.appendTo(parent || 'body'),
      _.tap(function () {
        if (timeout)
          setTimeout(function () {
            $.don_loader_end();
          }, timeout);
      })
    );
  };

  $.don_loader_end = function (container) {
    return $.remove($.find1(container || $1('body'), '.don_loader_wrap'));
  };

  $.don_loader_start3 = function (parent, opt, timeout) {
    $.don_loader_end();
    return _.go(
      $('.don_loader_wrap3'),
      $.remove,
      _.noop,
      _.t$(
        '\
      .don_loader_wrap3\
        .content\
          .body\
            .msg {{T("marpple_shop_select_page::Thumbnails are being created.")}}\
            .msg {{T("marpple_shop_select_page::Please, wait for a moment.")}}\
            .atebits-loader\
            '
      ),
      $.el,
      opt ? $.css(opt) : _.idtt,
      $.appendTo(parent || 'body'),
      _.tap(function () {
        if (timeout)
          setTimeout(function () {
            $.don_loader_end3();
          }, timeout);
      })
    );
  };
  $.don_loader_end3 = function () {
    return $.remove($('.don_loader_wrap3'));
  };
  $.don_loader_end2 = function (container) {
    return $.remove($.find1(container, '.don_loader_wrap2'));
  };

  $.post_load = function (url, data) {
    $.don_loader_start();
    return $.post(url, data);
  };

  $.post_load2 = function (url, data) {
    $.don_loader_start();
    return _.go($.post(url, data), _.tap(_.noop, $.don_loader_end));
  };

  $.component = $.ui = {
    pagination: function (container, opt, _t_each_template_func) {
      var container = $1(container);
      if (!container) return;
      var don_pgn_wrap = $.find1(container, '.don_pgn_wrap');
      var pagination = $.find1(container, '.don_pagination');
      var _t_pagination = _.t(
        'page',
        '\
      .pagination-wrap[style="visibility:hidden;"]\
        button.prev[type=button]\
        input.page_num[type=number value="{{page}}" min=1]\
        div.zzz ...\
        button.next[type=button]\
        .message'
      );

      var L = { lists: {} };

      var _option = _.extend(
        {
          data: { limit: 20 },
          real_data: _.noop,
          is_memoize: false,
          is_url_state: true,
          render_server: true,
          paging_animate: $.html,
        },
        _.isFunction(opt) ? opt() : opt
      );

      var pgn_items = window.box.sel(container);
      var timeout;
      return _.go(
        container,
        _.tap(
          (_option.get_page = function () {
            var offset = _option.is_url_state ? parseInt(G.query_str(location.search).offset) : 0;
            return offset ? offset / _option.data.limit + 1 : 1;
          }),
          _.all(_.always(pagination), _t_pagination),
          $.html
        ),
        _.tap(function () {
          var message = $.find1(pagination, '.message');
          var page_num = $.find1(pagination, 'input.page_num');
          var n_p_button = $.find(pagination, 'button');
          var first_page_val = $.val(page_num);
          var last_page = parseInt($.val(page_num));
          var is_ing = false;
          $.on(page_num, 'focus', function () {
            $.val(page_num, '');
          });
          $.on(page_num, 'blur', function () {
            $.val(page_num, last_page);
          });
          $.on(page_num, 'change', function () {
            if (is_ing) return $.val(page_num, last_page);
            is_ing = true;
            var page = parseInt($.val(page_num));
            last_page = page;

            _.go(
              void 0,
              function () {
                $.text(message, '');
                if (page >= 1) {
                  page_num.dataset = page_num.dataset || {};
                  page_num.dataset.before_val = page;
                  return page;
                }
                page_num.dataset = page_num.dataset || {};
                $.val(page_num, page_num.dataset.before_val || first_page_val);
                $.text(message, _.i('잘못된 페이지 입니다.'));
              },
              _.all(
                (_option['function ajax_call~2'] = _.if2(_.is_numeric)(
                  __(
                    function (page) {
                      _option.data.offset = page ? (page - 1) * _option.data.limit : 0;
                      return _.mr(page, L.lists[page]);
                    },
                    _.tap(function () {
                      timeout = setTimeout(function () {
                        $.don_loader_start(container);
                      }, 200);
                    }),
                    _.if2(function (page, results) {
                      return _option.is_memoize && results && results.length;
                    })(function (page, results) {
                      return results;
                    }).else(
                      __(
                        function (page) {
                          return _.mr(
                            page,
                            $.get(_option.url, _.extend({}, _option.data, _option.real_data()))
                          );
                        },
                        function (page, results) {
                          if (_.isArray(pgn_items)) window.box.set(container, results);
                          return (L.lists[page] = results);
                        }
                      )
                    ),
                    _t_each_template_func,
                    $.el,
                    _.all(
                      _.always(don_pgn_wrap),
                      function (el) {
                        return el == null ? '' : el;
                      },
                      _.always(container)
                    ),
                    _option.paging_animate
                  )
                )),
                _.if2(_.is_numeric)(function () {
                  if (_option.is_url_state)
                    window.history.replaceState(
                      history.state,
                      null,
                      G.update_query_str(location.href, 'offset', _option.data.offset)
                    );
                })
              ),
              _.catch(function () {
                $.html(don_pgn_wrap, '<div style="height: 300px;"></div>');
                return $.alert('페이지가 없습니다.');
              }),
              _.tap(function () {
                clearTimeout(timeout);
                $.don_loader_end(container);
              }),
              _.always(container),
              $.find('.don_pgn_container_loading'),
              $.remove,
              _.always(n_p_button),
              $.removeClass('ing'),
              function () {
                is_ing = false;
              }
            );
          });
          $.on(n_p_button, 'click', function (e) {
            var current_button = e.$currentTarget;
            if ($.hasClass(current_button, 'ing')) return;
            var is_next = $.hasClass(current_button, 'next');
            var done_page = is_next ? parseInt(page_num.max) : 1;
            var page = parseInt($.val(page_num));
            var is_same = page == done_page;
            if (is_same && $.has_class(message, 'ing')) return;
            if (is_same)
              return anime({
                targets: $.text(
                  $.addClass(message, 'ing'),
                  _.i((is_next ? '마지막' : '맨 앞') + ' 페이지 입니다.')
                ),
                duration: 1000,
                opacity: 0,
                easing: 'easeInOutQuart',
                delay: _.a(1000),
              }).finished.then(__(_.a(message), $.removeClass('ing'), $.css({ opacity: 1 }), $.text('')));
            $.text($.removeClass(message, 'ing'), '');
            $.addClass(n_p_button, 'ing');
            $.val(page_num, page + (is_next ? 1 : -1));
            $.trigger(page_num, 'change');
          });

          var pagination_wrap = $.find(pagination, '.pagination-wrap');
          if (_option.render_server) return $.css(pagination_wrap, { visibility: 'visible' });
          return _.go(parseInt($.val(page_num)), _option['function ajax_call~2'], function () {
            $.css(pagination_wrap, { visibility: 'visible' });
          });
        })
      );
    },
  };

  function transformOrientation(canvas, ctx, srcOrientation, w, h, img) {
    if (srcOrientation > 4 && srcOrientation < 9) {
      canvas.width = h;
      canvas.height = w;
    } else {
      canvas.width = w;
      canvas.height = h;
    }
    switch (srcOrientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, w, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, w, h);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, h);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, h, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, h, w);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, w);
        break;
      default:
        break;
    }
    ctx.drawImage(img, 0, 0, w, h);
  }

  function makeSrcOrientation(e) {
    if (is_image_orientation) return;
    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xffd8) return -2;
    var length = view.byteLength;
    var offset = 2;
    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xffe1) {
        if (view.getUint32((offset += 2), false) != 0x45786966) return -1;
        var little = view.getUint16((offset += 6), false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + i * 12, little) == 0x0112)
            return view.getUint16(offset + i * 12 + 8, little);
      } else if ((marker & 0xff00) != 0xff00) break;
      else offset += view.getUint16(offset, false);
    }
    return -1;
  }

  function make_data_url(file, srcOrientation, _height, next) {
    var reader2 = new FileReader();
    reader2.onload = function () {
      if (/\.gif$/i.test(file.name)) return next(reader2.result);
      var img = new Image();
      img.onload = function () {
        var width = (img.width * _height) / img.height;
        var height = _height;
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        transformOrientation(canvas, ctx, srcOrientation, width, height, img);
        next(canvas.toDataURL());
      };
      img.src = reader2.result;
    };
    reader2.readAsDataURL(file);
  }

  $.get_data_url = function (height) {
    height = height || 100;
    return function (file) {
      return _.go(
        new FileReader(),
        _.cb(function (reader, next) {
          reader.onload = function (e) {
            return make_data_url(file, makeSrcOrientation(e), height, next);
          };
          reader.readAsArrayBuffer(file);
        })
      );
    };
  };

  $.valid_files = function (input) {
    var accepts = _.map(input.getAttribute('accept').split(','), function (accept) {
      return (accept || '').toLowerCase();
    });
    var validate = !!_.find(input.files, function (file) {
      var extension = (file.name.match(/\.[^/.]+$/) || [''])[0].toLowerCase();
      return accepts.indexOf(file.type) == -1 && accepts.indexOf(extension) == -1;
    });
    if (validate) {
      _.defer(function () {
        input.type = '';
        input.type = 'file';
      });
      input.disabled = false;
    }
    return validate;
  };
  $.add_img_size_valid = function (input) {
    var min_size = 500;
    var max_size = 8000;
    return _.go(
      _.find(input.files, function (file) {
        if (file.type.indexOf('image') == -1 || file.type.indexOf('tiff') > -1) return;
        return _.go(
          file,
          _.cb(function (file, next) {
            if (file.size > 26214400) return _.go($.alert(T('File size is too large', { mb: 25 })), next);
            var reader = new FileReader();
            reader.onload = function (readerEvent) {
              var image = new Image();
              image.onload = function () {
                if (image.width < min_size && image.height < min_size)
                  return _.go($.alert(T('maker::At least 500px')), next);
                else if (image.width > max_size || image.height > max_size)
                  return _.go($.alert(T('maker::MAX 8000px')), next);
                else return next(false);
              };
              image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(file);
          })
        );
      }),
      function (file) {
        if (!file) return;
        _.defer(function () {
          input.type = '';
          input.type = 'file';
        });
        return Promise.reject('add_img_size_valid');
      }
    );
  };

  function dataURLToBlob(dataURL, type) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var raw = parts[1];
      return new Blob([raw], { type: type });
    }
    var parts = dataURL.split(BASE64_MARKER);
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) uInt8Array[i] = raw.charCodeAt(i);

    return new Blob([uInt8Array], { type: type });
  }

  function make_files(files, max_size) {
    max_size = max_size || 5000;
    if (
      _.find(files, function (file) {
        return file.size > 10485760;
      })
    )
      return Promise.reject('large');
    return _.go(
      files,
      _.map(function (file) {
        if (
          file.type.indexOf('image') == -1 ||
          file.type.indexOf('tiff') > -1 ||
          file.type.indexOf('gif') > -1
        )
          return Promise.resolve({ file: file, is_file: true });
        return _.go(
          file,
          _.cb(function (file, next) {
            var reader = new FileReader();
            reader.onload = next;
            reader.readAsDataURL(file);
          }),
          _.cb(function (readerEvent, next) {
            var image = new Image();
            image.onload = function () {
              next({
                image: image,
                file: file,
                width: image.width,
                height: image.height,
              });
            };
            image.src = readerEvent.target.result;
          })
        );
      }),
      _.map(function (img) {
        if (img.is_file) return Promise.resolve({ file: img.file, name: img.file.name });
        return new Promise(function (rs) {
          var reader2 = new FileReader();
          var width = img.width;
          var height = img.height;
          var file = img.file;
          reader2.onload = __(makeSrcOrientation, function (srcOrientation) {
            var canvas = document.createElement('canvas');
            if (width > max_size) {
              height *= max_size / width;
              width = max_size;
            }
            var ctx = canvas.getContext('2d');
            transformOrientation(canvas, ctx, srcOrientation, width, height, img.image);
            rs({
              file: dataURLToBlob(canvas.toDataURL(file.type), file.type),
              name: file.name,
            });
          });
          reader2.readAsArrayBuffer(file);
        });
      })
    );
  }

  var upload = $.upload;
  $.upload_exif = function (input, opt) {
    if (!_.val(input, 'files.length')) return upload($.el('<input type="file">'), opt);
    return _.go(
      make_files(input.files, opt.max_resize),
      _.filter(_.idtt),
      function (values) {
        if (!values.length) return { msg: '' };
        var form_data = new FormData();
        _.each(values, function (val) {
          form_data.append('files[]', val.file, val.name);
        });
        delete opt.max_resize;
        return upload(form_data, opt);
      },
      _.tap(function () {
        _.defer(function () {
          input.type = '';
          input.type = 'file';
        });
      }),
      _.catch(function (err) {
        _.defer(function () {
          input.type = '';
          input.type = 'file';
        });
        if (err == 'cancel') return err;
        if (err == 'large') return { msg: T('File size is too large', { mb: 10 }) };
        return Promise.reject(err);
      })
    );
  };

  $.uploadFileToUrl = function (url, font_original_name, image_type) {
    image_type = image_type || 'png';
    var blobBin = atob(url.split(',')[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: 'image/' + image_type });
    var formData = new FormData();
    formData.append('file', file, font_original_name + '.' + image_type);
    return upload(formData);
  };
  // /@fileUpload/file_only_original
  $.uploadFileToOnlyOriginalUrl = function (obj) {
    var url = obj.url;
    var original_name = obj.original_name || 'untitled';
    var image_type = obj.image_type || 'png';
    var blobBin = atob(url.split(',')[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: 'image/' + image_type });
    var formData = new FormData();
    formData.append('file', file, original_name + '.' + image_type);
    return $.upload(formData, {
      url: '/@fileUpload/file_only_original',
    });
  };

  $.downloadUrl = function (url, filename) {
    $.don_loader_start();
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function () {
      try {
        var a = document.createElement('a');
        var urlCreator = window.URL || window.webkitURL;
        a.href = urlCreator.createObjectURL(xhr.response);
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        $.don_loader_end();
        URL.revokeObjectURL(a.href);
        $.remove(a);
        $.don_loader_end();
      } catch (e) {
        $.don_loader_end();
        throw e;
      }
    };
    xhr.onerror = function () {
      $.don_loader_end();
    };
    xhr.open('GET', url);
    xhr.send();
  };
  // util
  $.safety_children = function f(el, sel) {
    return arguments.length == 1
      ? _(f, _, el)
      : $.children(el, sel)[0] || $.appendTo($.hide($.el(_.t('', '  ' + sel)())), el);
  };
  $.remove_delegate_target = function (e) {
    return $.remove(e.$delegateTarget), e;
  };

  $.selectable = __(
    _.val('$currentTarget'),
    _.tap($.siblings('.selected'), $.remove_class('selected')),
    $.add_class('selected')
  );

  $.selectable2 = __(_.tap($.siblings('.selected'), $.remove_class('selected')), $.add_class('selected'));
  $.selectable3 = __(
    _.tap($.siblings('.selected'), $.remove_class('selected')),
    _.tap($.parent, $.siblings, _.each(__($.find1('.selected'), $.remove_class('selected')))),
    $.add_class('selected')
  );

  $.attr_selectable = function f(el, attr_opt) {
    if (!attr_opt) return _(f, _, el);
    var attr = {};
    attr[attr_opt] = true;
    return _.go(
      el,
      _.tap(
        $.attr(attr),
        $.siblings,
        _.tap(function () {
          attr[attr_opt] = false;
        }),
        $.attr(attr)
      )
    );
  };

  $.toggle_attr = function f(el, attr) {
    if (!attr) return _(f, _, el);
    $.attr(el, attr) ? $.attr(el, attr, false) : $.attr(el, attr, true);
    return $.attr(el, attr);
  };

  $.tooltip = function (_opt) {
    var opt = _.extend(
      {
        data: {},
        template: _.t$('  .tooltip_empty'),
        class: '',
        target: '#header',
        auto_hide: false,
        duration: 2500,
        appending: _.idtt,
        appended: _.idtt,
        width: 300,
      },
      _opt
    );
    box.set('__tooltip__', opt.data);

    _.go(
      $1('body'),
      $.on('click', '#body', function (e) {
        var tooltip = $1('.don_tooltip');
        if (!tooltip) return;
        // $.remove(tooltip);
        $.off(e.delegateTarget, 'click', '#body');
      })
    );

    return _.go(
      opt.data,
      opt.template,
      _.t$('\
        .don_tooltip[_sel=__tooltip__]\
          .arrow\
          .wrapper\
            {{$}}'),
      _.tap(opt.appending),
      $.prepend_to($1('body')),
      $.add_class(opt.class),
      function (tooltip) {
        var tgt = _.is_element(opt.target) ? opt.target : $1(opt.target);
        var ps = $.offset(tgt);
        var vw = document.documentElement.clientWidth;
        if (G.is_pc_size()) vw = opt.no_max ? vw : Math.max(vw, 1280);
        var arrow = $.find1(tooltip, '.arrow');
        var mg = vw - (ps.left + $.outerWidth(tgt));

        $.css(tooltip, { top: ps.top + 36, width: opt.width });

        if (vw <= $.outerWidth(tooltip) + mg) {
          mg = (vw - opt.width) / 2;
          $.css(tooltip, { right: mg });
          $.css(arrow, { left: ps.left - 2 - mg });
        } else {
          $.css(tooltip, { right: mg - 2 });
          $.css(arrow, { right: 6.5 });
        }

        return _.go(tooltip, _.tap(opt.appended), _.tap($.fade_in), function (tooltip) {
          if (opt.auto_hide) {
            _.delay(function () {
              return _.go($.fade_out(tooltip), $.remove);
            }, opt.duration);
          }
          box.unset('__tooltip__');
        });
      }
    );
  };
  //frame의 showed랑 hiding, el_class = prevent_bg_event "뒤에 안누르게 할 경우"
  $.on_prevent_default = __(
    _.$('#body'),
    $.on('touchmove.prevent_default', function (e) {
      e.preventDefault();
    })
    // _.a(window),
    // $.on('DOMMouseScroll.prevent_default', function(e) { e.preventDefault(); }),
    // $.on('mousewheel.prevent_default', function(e) { e.preventDefault(); })
  );
  $.off_prevent_default = __(
    _.$('#body'),
    $.off('touchmove.prevent_default')
    // _.a(window),
    // $.off('DOMMouseScroll.prevent_default'),
    // $.off('mousewheel.prevent_default')
  );

  $.frame_close_bg = __(
    $.prepend(_.t$('\
      .frame_close_bg\
    ')()),
    $.on('click', '.frame_close_bg', function () {
      $.frame.close();
    })
  );

  //page의 showed랑 hiding .selected로 구분

  $.iscroll_on = _.tap(
    _.$('.iscroll_wrapper'),
    _.each(function (el) {
      // $.hide(el);
      if (el.myScroll) return el.myScroll.refresh();
      el.myScroll = new IScroll(el, {
        click: true,
      });
      el.myScroll.scrollToElement($.find1(el, '>.iscroll_ul_wrapper >.i_item.selected'), 0, true, true);
      // $.show(el);
      // _.delay(function() {
      // }, 10)
    })
  );

  $.iscroll_horizontal_on = _.tap(
    _.$('.iscroll_wrapper_horizontal'),
    _.each(function (el) {
      if (el.myScroll) return el.myScroll.refresh();
      _.go(
        el,
        $.find1('.iscroll_ul_wrapper'),
        _.add_arg($.find(' > .i_item')),
        function (ul_wrapper, lis) {
          var width = _.reduce(
            lis,
            function (acc, li) {
              return acc + $.outerWidth(li, true);
            },
            0
          );

          if ($.width(ul_wrapper) > width) return false;
          $.css(ul_wrapper, { width: width });
          return true;
        },
        function (need_scroll) {
          if (!need_scroll) return;
          el.myScroll = new IScroll(el, {
            click: true,
            scrollX: true,
            scrollY: false,
          });
          el.myScroll.scrollToElement($.find1(el, '>.iscroll_ul_wrapper >.i_item.selected'), 0, true, true);
        }
      );
    })
  );

  $.iscroll_pc_on = _.tap(
    $.find('.iscroll_wrapper'),
    _.each(function (el) {
      if (el.myScroll) return el.myScroll.refresh();
      el.myScroll = new IScroll(el, {
        mouseWheel: true,
        scrollbars: true,
        fadeScrollbars: true,
      });
      el.myScroll.scrollToElement($.find1(el, '>.iscroll_ul_wrapper >.i_item.selected'), 0, true, true);
    })
  );

  $.iscroll_off = _.tap(
    _.tap(
      $.find('.iscroll_wrapper_horizontal'),
      _.each(function (el) {
        if (!el.myScroll) return;
        // el.myScroll.scrollTo(0, 0, 0);
        el.myScroll.destroy();
        el.myScroll = null;
      })
    ),
    $.find('.iscroll_wrapper'),
    _.each(function (el) {
      if (!el.myScroll) return;
      // el.myScroll.scrollTo(0, 0, 0);
      el.myScroll.destroy();
      el.myScroll = null;
    })
  );
  //target으로 이동
  $.iscroll_to = function (scroll_wrapper_el, target) {
    scroll_wrapper_el.myScroll.scrollToElement(target, 500, true, true, IScroll.utils.ease.quadratic);
  };

  $.iscroll_to2 = function (scroll_wrapper_el, target) {
    scroll_wrapper_el.myScroll.scrollToElement(target, 0, true, true);
  };

  $.biggest_span_size = function (arr, font_size) {
    return _.go(
      _.mr(arr, font_size),
      _.t(
        'arr, font_size',
        '\
        .wrapper_biggest_span_size[style="visibility:hidden;position:absolute;top:-500px;left:-500px; font-size:{{font_size}}px;"]\
          {{_.go(arr, ',
        _.sum(_.t$('\
            span[style="display:block;"] {{$}}\
          ')),
        ')}}\
       '
      ),
      $.append_to($1('body')),
      $.width,
      Math.ceil,
      _.tap(function () {
        $.remove($1('.wrapper_biggest_span_size'));
      })
    );
  };

  $.get_font_size1 = function (img) {
    var can = document.createElement('canvas');
    can.width = img.width;
    can.height = img.height;
    var wid = can.width;
    var hei = can.height;
    var ctx = can.getContext('2d');
    $.append_to(can, $1('body'));
    ctx.drawImage(img, 0, 0);
    var data = ctx.getImageData(0, 0, can.width, can.height).data;
    var res = {};
    _.go(
      data,
      _.how_many(wid * 4),
      _.tap(
        _.map(function (arr) {
          return _.find_i(arr, function (v) {
            return v !== 0;
          });
        }),
        _.reject(function (v) {
          return v === -1;
        }),
        _.min,
        _.tap(function (a) {
          res.left = a / 4;
        })
      ),
      _.map(function (a) {
        return a.reverse();
      }),
      function (a) {
        return a.reverse();
      },
      _.map(function (arr) {
        return _.find_i(arr, function (v) {
          return v !== 0;
        });
      }),
      _.reject(function (v) {
        return v === -1;
      }),
      _.min,
      _.tap(function (a) {
        res.right = a / 4;
      })
    );

    var rect = {
      width: Math.round(wid - res.right - res.left),
    };
    ctx.fillStyle = 'blue';
    ctx.fillRect(0, 0, res.left, hei);
    // ctx.fillRect(0, 0, wid, res.top);
    ctx.fillRect(wid - res.right, 0, wid, hei);
    // ctx.fillRect(0, hei-res.bottom, wid, hei);
    return rect;
  };

  $.delegate_hide = __(_.val('$delegateTarget'), $.hide);
  $.stop_propagation = _.tap(function (e) {
    e.stopPropagation();
  });

  $.key_code = function (num) {
    return function () {
      return _.if2(_.v('keyCode'), _.is_equal(num))(_.pipe(arguments));
    };
  };

  $.find_el_by_sel = function (el, _sel) {
    if (!_sel) return _($.find_el_by_sel, el);
    return $1(el + '[_sel="' + _sel + '"]');
  };

  $.is_enter = $.key_code(13);

  $.val_len = function (el) {
    return ($.val(el) + '').length;
  };
  $.body_fixed = function (is_addclass, is_not_scroll) {
    var $body = $1('body');
    if (is_addclass) {
      if (G.is_pc_size() && $.height($body) > $.innerHeight(window))
        $.css($body, {
          'overflow-y': 'scroll',
        });
      var top = -$.scroll_top(window);
      $.css($.addClass($body, 'fixed'), { top: top });
    } else {
      var top = -parseInt($body.style.top);
      $.css($.removeClass($body, 'fixed'), { top: '', 'overflow-y': '' });
      if (!is_not_scroll) $.scroll_top2(window, top);
    }
  };

  $.inner_fixed = function (scroll_el, finder, is_fixed) {
    var el = $.find1(scroll_el, finder);
    if (is_fixed) {
      var top = -$.scroll_top(scroll_el);
      $.css(scroll_el, { 'overflow-y': 'hidden' });
      $.css(el, { top: top });
    } else {
      var top = -parseInt($.css(el, 'top'));
      $.css(scroll_el, { 'overflow-y': '' });
      $.css(el, { top: '' });
      $.scroll_top(scroll_el, top);
    }
  };

  var t_dialog_msg = _.if2(function (el_dialog_msg, kind) {
    return el_dialog_msg && !$1('#don_' + kind);
  })(
    __(
      _.spread(
        __(
          _.t(
            'msg',
            '\
    .don_dialog_msg[tabindex=1]\
      .content\
        .body \
          .msg\
            {{msg}}\
        .footer\
          .buttons\
            button.cancel[type=button] {{T("dialog_msg::No")}}\
            button.ok[type=button] {{T("dialog_msg::Yes")}}\
    '
          ),
          $.el
        ),
        _.idtt
      ),
      _.cb(function (el_dialog_msg, kind, next) {
        var is_already_body_fixed = !!$1('body.fixed');
        if (!is_already_body_fixed) $.body_fixed(true);
        el_dialog_msg.id = 'don_' + kind;
        var is_alert = kind == 'alert';
        if (is_alert) {
          $.remove($.find1(el_dialog_msg, '.buttons > button.cancel'));
          $.html($.find1(el_dialog_msg, '.buttons > button.ok'), T('dialog_msg::Ok'));
        }
        $.appendTo(el_dialog_msg, 'body');
        el_dialog_msg.focus();
        var true_callback = _(callback, true);
        $.on(
          el_dialog_msg,
          'keydown',
          __(
            _.val('keyCode'),
            _.if2(_.is_equal(27))(_(callback, is_alert)).else_if(_.is_equal(13))(true_callback)
          )
        );
        $.on($.find1(el_dialog_msg, '.buttons > button.cancel'), 'click', _(callback, false));
        $.on($.find1(el_dialog_msg, '.buttons > button.ok'), 'click', true_callback);
        function callback(b) {
          setTimeout(function () {
            $.remove(el_dialog_msg);
            next(b);
            if (!is_already_body_fixed) $.body_fixed();
          }, 0);
        }
      })
    )
  );

  $.alert = _(t_dialog_msg, _, 'alert');
  $.confirm = _(t_dialog_msg, _, 'confirm');

  $.anime = function (tgt, opt) {
    if (opt !== undefined) return anime(_.extend({ targets: tgt }, opt)).finished;
    return _.tap(function (el) {
      return anime(_.extend({ targets: el }, tgt)).finished;
    });
  };

  $.fade = function (targets, duration, opacity, easing) {
    return _.go(
      anime({
        targets: targets,
        opacity: opacity,
        duration: duration || 300,
        easing: easing || 'linear',
      }).finished,
      _.a(targets)
    );
  };
  $.fade_in = function (targets, duration, easing) {
    return $.fade(targets, duration, 1, easing);
  };
  $.fade_out = function (targets, duration, easing) {
    return $.fade(targets, duration, 0, easing);
  };

  var scroll_top2_task;
  function add_scroll_top2_task(target, animation_fn) {
    return new Promise(function (resolve) {
      if (scroll_top2_task) scroll_top2_task.resolve(anime.remove(scroll_top2_task.target));
      animation_fn().then(
        (scroll_top2_task = {
          target: target,
          resolve: function () {
            resolve((scroll_top2_task = void 0));
          },
        }).resolve
      );
    });
  }

  $.scroll_top2 = function (target, top, duration) {
    if (arguments.length < 2) return $.scroll_top(target);
    if (!target || target == window) target = 'html, body';
    return add_scroll_top2_task(target, function () {
      return anime({
        targets: target,
        scrollTop: top || 0,
        duration: duration || 0,
        easing: 'easeInOutQuart',
      }).finished;
    });
  };

  $.scroll_top_inner = function (parent_el, els, el, duration) {
    var i = _.indexOf(els, el);
    return $.scroll_top2(parent_el, i * $.innerHeight(el), duration);
  };

  !(function don_up_start(don_up) {
    don_up = don_up || $.el('<button id="don_up" type="button"></button>');
    don_up_scroll();
    $.append($1('body'), don_up);
    $.on(don_up, 'click', function (e) {
      if ($.has_class(e.currentTarget, 'show'))
        return anime({
          targets: 'html, body',
          scrollTop: 0,
          duration: 400,
          easing: 'easeInOutQuart',
        });
    });
    $.on(window, 'scroll', don_up_scroll);

    var is_show = false;
    var last_scroll = $.scroll_top(window);
    var cut_line = (window.innerHeight * 3) / 2;
    function don_up_scroll() {
      var top = $.scroll_top(window);
      var is_up = top < last_scroll;
      var over_cutline = cut_line < top;
      last_scroll = top;
      if (is_show) {
        if (!over_cutline || !is_up) {
          is_show = false
          $.removeClass(don_up, 'show');
        }
      } else {
        if (over_cutline && is_up) {
          is_show = true;
          $.addClass(don_up, 'show');
        }
      }
    }
  })();

  $.don_select = function (el, list) {
    if (!el) return;
    if ($.is(el, '.don_select_wrapper')) return;
    el = $.add_class($1(el), 'don_select_wrapper');
    list = (window.box.sel && window.box.sel(el)) || list;
    var current_value = $.attr(el, 'current_value');
    var value_key = $.attr(el, 'value_key') || 'id';
    var name_key = $.attr(el, 'name_key') || 'name';
    var select_name = $.attr(el, 'select_name') || 'don_select';
    var el_class = $.attr(el, 'el_class');
    var el_id = $.attr(el, 'el_id');
    var el_select = _.go(
      list,
      _.t$(
        '\
      select.don_select[name="' + select_name + '"]\
        {{_.sum($, ',
        _.t$('\
        option[value="{{$.' + value_key + '}}"] {{$.' + name_key + '}}'),
        ')}}\
      '
      ),
      $.el,
      $.css({
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        display: G.is_pc_size() ? 'none' : '',
      })
    );
    _.each($.find(el_select, 'option'), function (o) {
      o.selected = o.value == current_value;
    });
    $.append(el, el_select);
    var selected = _.find(list, function (data) {
      return data[value_key] == current_value;
    });
    if (selected) $.text($.find1(el, '> .selected'), selected[name_key]);
    $.on(el_select, 'change', function (e) {
      var val = $.val(e.currentTarget);
      var selected = _.find(list, function (data) {
        return data[value_key] == val;
      });
      $.text($.find1(el, '> .selected'), selected[name_key]);
      $.attr(el, 'current_value', selected[value_key]);
    });
    if (G.is_pc_size())
      $.on(el, 'click', '>.selected', function () {
        if (!$1('body > .don_select_list'))
          return _.go(
            list,
            _.t$(
              '\
          .don_select_list\
            .selected_true {{_.find_where($, { "' +
                value_key +
                '" : "' +
                $.attr(el, 'current_value') +
                '"}). ' +
                name_key +
                '}}\
            ul\
              {{_.sum($, ',
              _.t$('\
              li[value="{{$.' + value_key + '}}"] {{$.' + name_key + '}}'),
              ')}}'
            ),
            $.el,
            $.add_class(el_class),
            $.attr({ id: el_id }),
            $.css(_.extend({ position: 'absolute' }, $.offset(el))),
            _.tap(function (don_select_list) {
              var current_value = $.attr(el, 'current_value');
              _.each($.find(don_select_list, 'li'), function (o) {
                $.attr(o, 'selected', $.attr(o, 'value') == current_value);
              });
            }),
            $.appendTo($1('body')),
            $.on('click', 'li[selected="false"]', function (e) {
              var el_selected = $.find1(
                el_select,
                'option[value="' + $.attr(e.currentTarget, 'value') + '"]'
              );
              if (el_selected) el_selected.selected = true;
              $.trigger(el_select, 'change');
              $.remove(e.delegateTarget);
              $.off(document, 'click.don_selector');
            }),
            function () {
              _.defer(function () {
                $.on(document, 'click.don_selector', function (e) {
                  var don_select_list = $1('body > .don_select_list');
                  if (!don_select_list || $.closest(e.target, '.don_select_list')) return;
                  $.remove(don_select_list);
                  $.off(document, 'click.don_selector');
                });
              });
            }
          );
      });
  };

  $.img_onload = function (url) {
    return new Promise(function (resolve, reject) {
      var img = new Image();
      img.src = url;
      var pass = false;
      img.onload = function () {
        pass = true;
        resolve(url);
      };
      setTimeout(function () {
        if (pass) return;
        reject(new Error('on_load error'));
      }, 5000);
    });
  };

  $.active_loader_by_time = function (time, fn) {
    var is_done = false;
    var is_started = false;
    setTimeout(function () {
      if (is_done) return;
      $.don_loader_start();
      is_started = true;
    }, time);
    return _.go(fn.then ? fn : fn(), function (arg) {
      is_done = true;
      if (is_started) $.don_loader_end();
      return arg;
    });
  };
})(_p, $);
